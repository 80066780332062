import React from 'react';

export interface HrProps {
  borderThickness?: number;
  borderColor?: string;
  borderRadius?: string;
  opacity?: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginString?: string;
  className?: String;
  borderStyle?: String;
  filter?: String;
  width?: String;
}

const Hr: React.FC<HrProps> = (props) => {
  const {
    opacity = 0.33,
    marginTop = 20,
    marginRight = 0,
    marginBottom = 20,
    marginLeft = 0,
    marginString = '',
    borderThickness = 1,
    borderColor = 'rgba(151, 151, 151, 1)',
    borderStyle = 'solid',
    borderRadius = '0',
    width = '100%',
    filter = '',
  } = props;
  return (
    <>
      <hr data-testid="test-hr" />

      <style jsx>{`
        hr {
          border-top: ${borderThickness}px ${borderStyle} ${borderColor};
          border-bottom: 0;
          border-right: 0;
          border-left: 0;
          border-radius: ${borderRadius};
          opacity: ${opacity};
          margin: ${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px;
          filter: ${filter};
          width: ${width};
          ${marginString ? `margin:${marginString}` : ''}
        }
      `}</style>
    </>
  );
};

export default Hr;
