import React, { Fragment } from 'react';

import { Address, Info } from 'components/Shared/Icons/Icons';
import SideIconPanel from 'components/Shared/Merchant/MoreInfo/SideIconPanel/SideIconPanel';
import Separator from 'components/Shared/Separator/Separator';
import { colors, fontSize } from 'styles/cp';

export interface MerchantInfoProps {
  companyEmail?: string;
  companyPhone?: string;
  companyWebAddr?: string;
  companyAddress?: Record<string, string>;
  number: string;
}

const MerchantMoreInfo: React.FC<MerchantInfoProps> = ({
  companyEmail,
  companyPhone,
  companyWebAddr,
  companyAddress: companyAddressExtended,
  number,
}) => {
  let companyAddress;
  if (companyAddressExtended) {
    const { street1 = '', city = '', stateOrRegion = '', postalCode = '' } = companyAddressExtended;
    if (city !== null && stateOrRegion !== null) {
      companyAddress = `${street1 !== null ? street1 + ',' : ''} 
        ${city}, ${stateOrRegion} ${postalCode !== null ? postalCode : ''}`;
    }
  }

  return (
    <Fragment>
      {/*language=SCSS*/}
      <style jsx>{`
        .contact-info {
          text-align: center;
          word-break: break-word;

          .w {
            display: inline-block;

            .contact {
              display: block;
            }

            .contact:not(:first-child) {
              margin-top: 7px;
            }

            .contact-link {
              color: ${colors.blue};
              font-size: ${fontSize.xs};
              text-decoration: none;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }

            .phone-number {
              font-size: ${fontSize.xs};
              color: ${colors.blue};
              text-decoration: none;
            }
          }
        }
      `}</style>

      <div className="contact-info">
        <div className="w">
          {(companyPhone || companyEmail || companyWebAddr) && (
            <SideIconPanel icon={Info} color={colors.gray} aria-label="Merchant-details">
              {companyEmail && (
                <a
                  href={`mailto:${companyEmail}?subject=Question about invoice ${number}`}
                  className="contact contact-link"
                >
                  {companyEmail}
                </a>
              )}
              {companyPhone && (
                <a href={`tel:${companyPhone}`} className="contact phone-number">
                  {companyPhone}
                </a>
              )}
              {companyWebAddr && (
                <a
                  href={
                    companyWebAddr.indexOf('http') !== -1
                      ? companyWebAddr
                      : 'http://' + companyWebAddr
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact contact-link"
                >
                  {companyWebAddr}
                </a>
              )}

              {companyAddress && <Separator height={22} />}
            </SideIconPanel>
          )}

          {companyAddress && (
            <SideIconPanel icon={Address} color={colors.gray} aria-label="Merchant-address">
              <a
                className="contact contact-link"
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                  companyAddress
                )}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {companyAddress}
              </a>
            </SideIconPanel>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default MerchantMoreInfo;
