import React, { Fragment } from 'react';

export interface Props {
  icon: Function;
  color: string;
  children: React.ReactNode | string | null;
  ['aria-label']?: string;
}

const SideIconPanel: React.FC<Props> = ({
  icon: Icon,
  color,
  children,
  'aria-label': ariaLabel,
}) => {
  const getIcon = () => {
    return Icon && <Icon color={color} aria-label={ariaLabel} />;
  };

  return (
    <Fragment>
      <style jsx>{`
        .side-icon-wrapper {
          flex-wrap: wrap;

          .icon {
            margin-top: 1px;
          }

          .content {
            margin-left: 10px;
            text-align: left;
          }
        }
      `}</style>

      <div className="flex side-icon-wrapper">
        <div className="icon">{getIcon()}</div>
        <div className="content flex-any trunc">{children}</div>
      </div>
    </Fragment>
  );
};

export default SideIconPanel;
