import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TrustEImage from 'components/Core/TrustSection/TrustEImage/TrustEImage';
import Card from 'components/Shared/Card/Card';
import Hr from 'components/Shared/Hr/Hr';
import { colors, fontSize } from 'styles/cp';

export interface TrustCheckoutSealProps {
  cdn: string;
}

const TrustCheckoutSeal: React.FC<TrustCheckoutSealProps> = ({ cdn }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <div>
        <Card>
          <div className="wrapper">
            <div className="section">
              <img
                style={{
                  paddingRight: '14px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
                src={`${cdn}/audit_defense.svg`}
                alt="audit defense"
                height="34px"
              />
              <div className="text">
                <FormattedMessage
                  id="TRUSTED_CHECKOUT_SEAL_TEXT"
                  defaultMessage="We're committed to keeping your data and privacy safe. Learn more about Intuit’s "
                />
                <a
                  href="https://security.intuit.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: colors.intuit_blue }}
                >
                  <FormattedMessage
                    id="TRUSTED_CHECKOUT_SEAL_LINK"
                    defaultMessage="Security practice"
                  />
                </a>
              </div>
            </div>
            <Hr marginBottom={18} marginTop={18} />
            <div className="section">
              <TrustEImage cdn={cdn} intl={intl} />
              <img
                style={{ paddingLeft: '12px', height: '24px' }}
                src={`${cdn}/pci_compliant.png`}
                alt="pci"
              />
            </div>
          </div>
        </Card>
      </div>
      <style jsx>{`
        .wrapper {
          padding: 10px 18px;
          .section {
            display: flex;
            flex-direction: row;
            .text {
              font-weight: 500;
              font-size: ${fontSize.xxs};
            }
          }
        }
      `}</style>
    </Fragment>
  );
};

export default TrustCheckoutSeal;
