import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TrustEImage from 'components/Core/TrustSection/TrustEImage/TrustEImage';
import { Lock } from 'components/Shared/Icons/Icons';
import { colors, fontSize } from 'styles/cp';

export interface TrustEProps {
  cdn: string;
}

const TrustESeal: React.FC<TrustEProps> = ({ cdn }) => {
  const intl = useIntl();
  return (
    <Fragment>
      <style jsx>{`
        .lock-wrapper {
          line-height: 15px;
        }
        .no-wrap {
          white-space: nowrap;
        }
        .msg {
          font-family: AvenirNextforINTUIT-Regular;
          font-size: ${fontSize.xxs};
          color: ${colors.gray};
          vertical-align: text-top;
          display: inline-block;
          margin-left: 3px;
          padding-top: 3px;
          white-space: normal;
        }
      `}</style>

      <TrustEImage cdn={cdn} intl={intl} />
      <div className="lock-wrapper no-wrap">
        <Lock width={11} height={13} color={colors.gray} />
        <span className="msg">
          <FormattedMessage
            id="PAYFLOW_SIDE_LOCK_TEXT"
            defaultMessage="Information is protected and kept confidential"
          />
        </span>
      </div>
    </Fragment>
  );
};

export default TrustESeal;
