import React from 'react';
import { FormattedDate } from 'react-intl';

import { getDateFromString } from 'shared/utils';

export interface FormattedDateNoTimeZoneProps {
  value: any;
}

const FormattedDateNoTimeZone: React.FC<FormattedDateNoTimeZoneProps> = ({ value }) => {
  let date;
  if (typeof value === 'string') {
    if (isNaN(Number(value))) {
      date = getDateFromString(value);
    } else {
      date = new Date(parseInt(value));
    }
  } else {
    date = value;
  }
  return (
    <FormattedDate value={date} timeZone={undefined} month="long" year="numeric" day="numeric" />
  );
};

export default FormattedDateNoTimeZone;
