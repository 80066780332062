import React, { Fragment } from 'react';

export interface Props {
  width?: number;
  height?: number;
  display?: string;
}

const Separator: React.FC<Props> = ({ width, height, display }) => (
  <Fragment>
    <style jsx>{`
      div {
        width: ${width || 0}px;
        height: ${height || 0}px;
        display: ${display || 'block'};
      }
    `}</style>

    <div data-testid="test-separator-component" />
  </Fragment>
);

export default Separator;
