import React from 'react';

import TrustCheckoutSeal from 'components/Core/TrustSection/TrustCheckoutSeal/TrustCheckoutSeal';
import TrustESeal from 'components/Core/TrustSection/TrustESeal/TrustESeal';
import { useIsMobileScreen } from 'hooks/useIsMobile';
import { getTrustedCheckoutVariation } from 'store/ixp/selector';
import { useSelector } from 'store/utils';

export interface TrustSectionProps {
  cdn: string;
  isMobileVariation?: boolean;
}

const TrustSection: React.FC<TrustSectionProps> = ({ cdn, isMobileVariation = false }) => {
  const isTrustedCheckoutEnabled = useSelector(getTrustedCheckoutVariation);
  const isMobile = useIsMobileScreen();
  const isDisplayTrustedVariation = isMobile
    ? isMobileVariation && !!isTrustedCheckoutEnabled
    : !!isTrustedCheckoutEnabled;

  return (
    <div>
      {!isDisplayTrustedVariation && <TrustESeal cdn={cdn} />}
      {isDisplayTrustedVariation && <TrustCheckoutSeal cdn={cdn} />}
    </div>
  );
};

export default TrustSection;
