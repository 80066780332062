import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Card from 'components/Shared/Card/Card';
import ExtendBtn from 'components/Shared/ExtendBtn/ExtendBtn';
import MerchantMoreInfo from 'components/Shared/Merchant/MoreInfo/MerchantMoreInfo';
import SegmentIO from 'reporting/SegmentIO';
import { colors } from 'styles/cp';

export type Props = {
  companyEmail?: string;
  companyPhone?: string;
  companyWebAddr?: string;
  companyAddress?: Record<string, string>;
  number: string;
};

const ContactMerchantCard = (props: Props) => {
  const [isContactMerchantOpen, setIisContactMerchantOpen] = useState(false);

  const getExtendBtn = () => {
    return (
      <ExtendBtn
        opened={isContactMerchantOpen}
        handler={() => {
          setIisContactMerchantOpen(!isContactMerchantOpen);
          SegmentIO.clickContactInformation();
        }}
        display="block"
      />
    );
  };
  const moreInfo = {
    companyEmail: props.companyEmail,
    companyPhone: props.companyPhone,
    companyWebAddr: props.companyWebAddr,
    companyAddress: props.companyAddress,
    number: props.number,
  };
  // we should peak the info in this order
  let visibleContactInfo;
  if (moreInfo.companyEmail) {
    visibleContactInfo = (
      <div className="c cp-link">
        <FormattedMessage id="SETTINGS_EMAIL" defaultMessage="Email" />
        <span>:&nbsp;</span>
        <a
          href={`mailto:${moreInfo.companyEmail}?subject=${encodeURIComponent(
            `Question about invoice ${moreInfo.number}`
          )}`}
          className="contact contact-link"
        >
          {moreInfo.companyEmail}
        </a>
      </div>
    );
    delete moreInfo.companyEmail;
  } else if (moreInfo.companyPhone) {
    visibleContactInfo = (
      <div className="c cp-link">
        <FormattedMessage id="SETTINGS_PHONE" defaultMessage="Phone" />
        <span>:&nbsp;</span>
        <a href={`tel:${moreInfo.companyPhone}`} className="contact phone-number">
          {moreInfo.companyPhone}
        </a>
      </div>
    );
    delete moreInfo.companyPhone;
  } else if (moreInfo.companyWebAddr) {
    visibleContactInfo = (
      <div className="c cp-link">
        <FormattedMessage id="SETTINGS_WEB" defaultMessage="Web" />
        <span>:&nbsp;</span>
        <a
          href={
            moreInfo.companyWebAddr.indexOf('http') > -1
              ? moreInfo.companyWebAddr
              : 'http://' + moreInfo.companyWebAddr
          }
          target="_blank"
          rel="noopener noreferrer"
          className="contact contact-link"
        >
          {moreInfo.companyWebAddr}
        </a>
      </div>
    );
    delete moreInfo.companyWebAddr;
  } else if (moreInfo.companyAddress) {
    const {
      street1 = '',
      city = '',
      stateOrRegion = '',
      postalCode = '',
    } = moreInfo.companyAddress;
    const companyAddress = `${street1}, ${city}, ${stateOrRegion} ${postalCode}`;
    visibleContactInfo = (
      <div className="c cp-link">
        <FormattedMessage id="SETTINGS_ADDRESS" defaultMessage="Address" />
        <span>:&nbsp;</span>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(companyAddress)}`}
          rel="noopener noreferrer"
          className="contact contact-link"
          target="_blank"
        >
          {companyAddress}
        </a>
      </div>
    );
    delete moreInfo.companyAddress;
  }

  // if nothing left to show in the more info collapsible dont show it
  const shouldShowMoreInfo =
    moreInfo.companyPhone ||
    moreInfo.companyEmail ||
    moreInfo.companyWebAddr ||
    (moreInfo.companyAddress &&
      moreInfo.companyAddress.city &&
      moreInfo.companyAddress.stateOrRegion);
  return (
    <Fragment>
      <style jsx>{`
        .w {
          padding: 10px 18px;
          position: relative;

          .c-n {
            font-family: AvenirNextforINTUIT-Medium;
            padding: 8px 0 0 0;
          }

          > label {
            display: block;
          }
        }

        .w-open {
          padding: 0 18px;

          max-height: ${isContactMerchantOpen ? '250px' : 0};
          transition: all 0.35s ease-in-out;
          overflow: hidden;

          :global(svg) {
            transition: all 0.15s linear;
          }

          :global(.contact-info) {
            padding-bottom: 10px;
            display: flex;
            a {
              text-decoration: none;
              cursor: pointer;
              color: ${colors.blue};
            }
          }
        }

        :global(.c) {
          font-size: 14px;
          font-family: AvenirNextforINTUIT-Regular;
        }

        :global(.c a) {
          text-decoration: none;
          color: inherit;

          &:hover {
            color: ${colors.blue};
          }
        }

        :global(.contact.contact-link) {
        }
      `}</style>

      <Card>
        <div className="w">
          <div className="c-n">
            <FormattedMessage id="MERCHANT_DETAILS" defaultMessage="Merchant details" />
          </div>
          {visibleContactInfo}
          {shouldShowMoreInfo && <div style={{ display: 'flex' }}>{getExtendBtn()}</div>}
        </div>
        {shouldShowMoreInfo && isContactMerchantOpen && (
          <div className="w-open">
            <MerchantMoreInfo {...moreInfo} />
          </div>
        )}
      </Card>
    </Fragment>
  );
};

export default ContactMerchantCard;
